(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

const recaptcha = require('./recaptcha');

var app = {
    init: function () {
        if ($('.order-summary-footer').length > 0) {
            recaptcha.render();
            $(".order-summary-footer").on("submit", "form.submit-order", function(e) {
                // disable button on first click to prevent resubmitting
                $(this).find("button").attr('disabled', 'disabled');

                if ($('.g-recaptcha').next('#g-recaptcha-response').val() === '') {
                    // prevent form submit and full page loading screen until after recaptcha finishes
                    e.preventDefault();
                    $('.g-recaptcha').trigger('execute.recaptcha', function() {
                        $('form.submit-order button').attr('disabled', false)[0].click();
                    });
                }
            });
        }
    }
};

$(document).ready(function () {
    app.init();
});
},{"./recaptcha":2}],2:[function(require,module,exports){
'use strict';

function render() {
    var $captcha = $('.g-recaptcha');
    if ($captcha.length !== 0) {

        if (typeof grecaptcha !== 'undefined' && typeof grecaptcha.render !== 'undefined') {
            // init & display the recaptcha badge
            try {
                grecaptcha.render($captcha[0], {
                    'sitekey' : $captcha.data('sitekey'),
                    'callback' : tokenCallback,
                    'expired-callback': expiredCallback
                });
                $('.grecaptcha-badge').addClass('visible');
            } catch (error) {
                // recaptcha has already rendered, do nothing
                console.log(error);
            }
        }

        var $inputResponse = $captcha.next('#g-recaptcha-response');
        var $submitter;

        // manual recaptcha execute for ajax calls
        $captcha.on('execute.recaptcha', function(e, callback) {
            $submitter = callback;

            if ($inputResponse.val() === '') {
                grecaptcha.execute().then(function(token) {
                    tokenCallback(token);
                });
            } else {
                $submitter();
            }
        });

        // when recapcha finishes, either call the manual callback, or resubmit the original form
        function tokenCallback(token) {
            $inputResponse.val(token);
            $('form.submit-order').append('<input type="hidden" name="g-recaptcha-response" value="' + token + '"/>');
            if (typeof $submitter === 'function') {
                $submitter();
            } else {
                // undisable the button if clicking it disables it
                $submitter.attr('disabled', false);
                $submitter[0].click();
            }
        }

        // wipe the recaptcha value on expiration
        function expiredCallback() {
            $inputResponse.val('');
        }
    }
}

module.exports = {
    render: render
};
},{}]},{},[1]);
